import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import ReCAPTCHA from 'react-google-recaptcha'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Layout from '../components/layout'
import PageTitle from '../components/page-title'
import SEO from '../components/seo'
import { myContext } from '../../provider'

const Content = styled(Row)`
  margin: 3.125rem 0;
`

const Padded = styled(Row)`
  margin: 2rem 0;
`

const StyledButton = styled(Button)`
  &:disabled {
    background: gray;

    &:hover {
      cursor: not-allowed;
    }
  }

  margin-top: 2rem;
`
const Renewal = ({ data }) => {
  const [captcha, setCaptcha] = useState(false)
  const handleCaptcha = (e) => {
    setCaptcha(true)
  }
  return (
    <myContext.Consumer>
      {({ language }) => {
        const renewalText = data.allContentfulRenewal.nodes.find(
          (node) => node.language === language
        )
        return (
          <Layout>
            <SEO title="Renewal" />
            <PageTitle title={renewalText.title} />
            <Container style={{ minHeight: '70vh' }}>
              <Row>
                <div
                  dangerouslySetInnerHTML={{
                    __html: renewalText.description.childMarkdownRemark.html,
                  }}
                ></div>
              </Row>
              <Padded>
                <h4>{renewalText.contactInfoLabel}</h4>
              </Padded>
              <hr />
              <Content>
                <Col xs={12} md={10}>
                  <Form method="POST" action="https://formspree.io/f/xvodlnwb">
                    <Form.Group>
                      <Form.Label>{renewalText.firstNameLabel} *</Form.Label>
                      <Form.Control type="input" name="FirstName" required />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{renewalText.lastNameLabel} *</Form.Label>
                      <Form.Control type="input" name="LastName" required />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{renewalText.phoneLabel} *</Form.Label>
                      <Form.Control type="tel" name="PhoneNumber" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{renewalText.dateOfBirthLabel} *</Form.Label>
                      <Form.Control
                        type="input"
                        name="DateOfBirth"
                        placeholder="MM/DD/YYYY"
                        required
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{renewalText.emailLabel} *</Form.Label>
                      <Form.Control type="email" name="Email" required />
                      <span>{renewalText.emailSubtext}</span>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{renewalText.expiryLabel} * </Form.Label>
                      <Form.Control type="input" name="PolicyExpiry" required />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{renewalText.licenseLabel} *</Form.Label>
                      <Form.Control
                        type="input"
                        name="LicenseNumber"
                        required
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{renewalText.needLabel} *</Form.Label>
                      {renewalText.needOptions.map((need) => (
                        <Form.Check name={need} label={need} />
                      ))}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{renewalText.vehicleLabel} *</Form.Label>
                      <Form.Control type="input" name="Vehicle" required />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{renewalText.commentsLabel}</Form.Label>
                      <Form.Control as="textarea" name="Comments" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{renewalText.hearLabel}</Form.Label>
                      {renewalText.hearOptions.map((hear) => (
                        <Form.Check name={hear} label={hear} />
                      ))}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{renewalText.consentLabel} *</Form.Label>
                      <Form.Check
                        required
                        name="consent1"
                        label={renewalText.consentText1.consentText1}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{renewalText.consentLabel} *</Form.Label>
                      <Form.Check
                        required
                        name="consent2"
                        label={renewalText.consentText2.consentText2}
                      />
                    </Form.Group>
                    <ReCAPTCHA
                      sitekey="6LcLg48cAAAAAMFsUcWDkkqCnkgpOBs3tWhmT_Mr"
                      onChange={handleCaptcha}
                    />
                    <StyledButton type="submit" disabled={!captcha}>
                      {renewalText.submitLabel}
                    </StyledButton>
                  </Form>
                </Col>
              </Content>
            </Container>
          </Layout>
        )
      }}
    </myContext.Consumer>
  )
}

export const pageQuery = graphql`
  query RenewalPageQuery {
    allContentfulRenewal {
      nodes {
        id
        cityLabel
        commentsLabel
        consentLabel
        consentText1 {
          consentText1
        }
        consentText2 {
          consentText2
        }
        contactInfoLabel
        dateOfBirthLabel
        description {
          childMarkdownRemark {
            html
          }
        }
        emailLabel
        emailSubtext
        expiryLabel
        firstNameLabel
        hearLabel
        hearOptions
        language
        lastNameLabel
        licenseLabel
        needLabel
        needOptions
        phoneLabel
        submitLabel
        title
        vehicleLabel
      }
    }
  }
`
export default Renewal
